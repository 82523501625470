import * as React from "react"
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import {GatsbyImage, StaticImage} from "gatsby-plugin-image";
import {graphql} from "gatsby"

export const pageQuery = graphql`
  query {
      image: file(
        absolutePath: { glob: "**/src/images/pauli.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 300)
        }
      }
    }
`

const AboutPage = ({data}) => {
    console.log(data);
    return (
        <Layout landing={false} active={"about"}>
            <SEO title={"Über Pauli | Portfolio"} about={true}></SEO>
            <div className="about__outer">
                <div className="about__inner">
                    <div className="top">
                        <div className="animatedtext">
                            <p>
                                Paul Petritsch is a freelance designer and advisor living in Berlin, Germany
                                specialising in
                                webdesign &
                                development, interactive experiences, creative direction, conceptual design and
                                digitalisation. Paul
                                studied information design at the FH JOANNEUM and is a certified software developer.
                                Being a
                                creative
                                person he is also a professional tattoo artist focusing on tribals and ignorant-styled
                                tattoos.
                            </p>
                        </div>
                        <div className="animatedimage">
                            <GatsbyImage image={data.image.childImageSharp.gatsbyImageData} alt={"Paul Petritsch"}></GatsbyImage>
                        </div>
                    </div>
                    <div className="bottom">
                        <a href={"tel:+4369010194808"} className="social animatedlinks1">
                            <div className="anim1"><span>(PHONE)</span></div>
                            <div className="anim2">
                                <span className="social-hover">
                                    +43 690 101 94 808
                                </span>
                            </div>
                        </a>
                        <a href={"mailto:yo@paulpetritsch.fyi"} className="social animatedlinks2">
                            <div className="anim1"><span>(E-MAIL)</span></div>
                            <div className="anim2">
                                <span className="social-hover">
                                    yo@paulpetritsch.fyi
                                </span>
                            </div>
                        </a>
                        <a href={"https://www.instagram.com/instapauli_/"} target={"_blank"} className="social animatedlinks3">
                            <div className="anim1"><span>(INSTAGRAM)</span></div>
                            <div className="anim2">
                                <span className="social-hover">
                                    instapauli_
                                </span>
                            </div>
                        </a>
                        <a href={"https://www.linkedin.com/in/paul-petritsch/"} target={"_blank"} className="social animatedlinks4">
                            <div className="anim1"><span>(LINKEDIN)</span></div>
                            <div className="anim2">
                                <span className="social-hover">
                                    paul-petritsch
                                </span>
                            </div>
                        </a>
                        <a href={"http://playlist.paulpetritsch.fyi/"} target={"_blank"} className="social animatedlinks5">
                            <div className="anim1"><span>(PLAYLIST)</span></div>
                            <div className="anim2">
                                <span className="social-hover">
                                    moving me atm
                                </span>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

        </Layout>
    )
}

export default AboutPage

